import React from "react"
import { graphql } from "gatsby"
import { getPath } from "../helpers/slugHelper"
import { ThemeProvider } from "styled-components"
import { theme } from "../assets/styles/styled-components/theme"
import Components from "../components"
import { AppProvider } from "../helpers/appContext"

function parseQueryResult(data) {
  let story = {}
  let globalSettings = {}

  data.allStoryblokEntry.edges.forEach((edge) => {
    const { node } = edge
    const content = JSON.parse(node.content)

    if (node.full_slug === "hult/404") {
      story = node
      story.content = content
    }

    if (node.full_slug === "hult/global") {
      globalSettings = node
      globalSettings.content = content
    }
  })

  return { story, globalSettings }
}

const NotFoundPage = ({ data }) => {
  const { story, globalSettings } = parseQueryResult(data)

  return (
    <div className="full-container">
        <AppProvider>
      <ThemeProvider theme={theme}>
      {React.createElement(Components(story.content.component), {
        key: story.content._uid,
        blok: story.content,
        global: globalSettings.content,
        path: `/${getPath(story.full_slug, story.path)}`,
      })}
      </ThemeProvider>
      </AppProvider>
    </div>
  )
}

export default NotFoundPage

export const query = graphql`
  query {
    allStoryblokEntry(filter: { slug: { in: ["404", "global"] } }) {
      edges {
        node {
          id
          name
          created_at
          uuid
          slug
          full_slug
          content
          is_startpage
          parent_id
          group_id
        }
      }
    }
  }
`
